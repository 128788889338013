<template>
  <search-item
    :item="item"
    @click="goToAccount(item)"
  >
    <div class="d-flex align-center">
      <span class="text--disabled">{{search.columns[0].text}}</span>
      <v-spacer />
      <span>{{item['c0'] || $t('t.Unknown')}}</span>
    </div>
    <div class="d-flex align-center">
      <span class="text--disabled">{{search.columns[1].text}}</span>
      <v-spacer />
      <column-mapper
        class="my-n2"
        :column="search.columns[1]"
        :value="item['c1']"
      />
    </div>
    <div class="d-flex align-center">
      <span class="text--disabled">{{search.columns[2].text}}</span>
      <v-spacer />
      <column-mapper
        :column="search.columns[2]"
        :value="item['c2']"
      />
    </div>
  </search-item>
</template>

<script>
import Search from '@/pages/search/controllers'

export default {
  name: 'search-item-accounts',
  inheritAttrs: false,
  components: {
    ColumnMapper: () => import('@/pages/search/components/column-mapper'),
    SearchItem: () => import('@/pages/mobility/components/search-item')
  },
  methods: {
    goToAccount (item) {
      this.$router.push({ name: 'mobility-account', params: { id: item.id } })
    }
  },
  props: {
    item: {
      required: true,
      type: Object
    },
    search: {
      required: true,
      type: Search
    }
  }
}
</script>
